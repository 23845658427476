<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="container">
      <div class="wrapper">
        <div class="content-wrapper">
          <div class="card-body">
            <div class="card pt-2 p-2">
              <div class="row mb-2 mt-3">
                <div class="col-md-4 mt-4 text-center">
                  <strong>
                    GUIA COMPRAS SIN TRANSPORTE
                  </strong>
                </div>
                <div class="col-md-8">
                  <div class="row ml-4">
                    <!-- Número Guía -->
                    <div class="col-md-4">
                      <label for="guia">N° GUIA</label>
                      <input
                        type="number"
                        v-model="form.numero_guia"
                        style="color: #ff0000; font-weight: bold"
                        class="form-control form-control-sm"
                        @change="buscarGuia()"
                        :class="
                          $v.form.numero_guia.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <!-- Digito Verificación -->
                    <div class="col-md-3">
                      <label for="digito_verificacion">DV</label>
                      <input
                        type="number"
                        v-model="form.digito_verificacion"
                        style="color: #ff0000; font-weight: bold"
                        class="form-control form-control-sm"
                        :class="
                          $v.form.digito_verificacion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="!form.numero_guia"
                      />
                      <div class="error" v-if="!form.numero_guia">
                        <span class="badge bg-danger">Digite Número Guía</span>
                      </div>
                    </div>
                    <!-- Tipo Producto -->
                    <div class="col-md-5">
                      <label for="producto">TIPO PRODUCTO</label>
                      <v-select
                        v-model="tipo_producto"
                        class="form-control form-control-sm p-0"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.tipo_productos"
                        @input="selectTipoProducto()"
                        :class="[
                          $v.form.tipo_producto_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2 mt-2">
                <div class="row" style="font-size: 12px">
                  <!-- Lugar Expedición -->
                  <div class="form-group col-sm-6">
                    <label for="fecha_expedicion">LUGAR DE EXPEDICION</label>
                    <v-select
                      v-model="ciudad_origen"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.ciudades"
                      @input="selectCiudadOrigen()"
                      :class="[
                        $v.form.ciudad_origen_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    ></v-select>
                  </div>
                  <!-- Fecha Hora Expedición -->
                  <div class="form-group col-md-6">
                    <label for="fecha_expedicion1"
                      >FECHA Y HORA DE EXPEDICION</label
                    >
                    <div class="row ml-1">
                      <input
                        type="date"
                        style="font-size: 13px"
                        v-model="form.fecha_expedicion"
                        class="form-control form-control-sm col-md-6"
                        @input="validaFechas()"
                        :class="
                          $v.form.fecha_expedicion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                      <input
                        type="text"
                        class="form-control form-control-sm col-md-5"
                        style="font-size: 13px"
                        v-model="form.hora_expedicion"
                        @input="validaFechas()"
                        v-mask="{
                          mask: '99:99',
                          hourFormat: '24',
                        }"
                        :class="
                          $v.form.hora_expedicion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="row" style="font-size: 12px">
                  <!-- Producto Liquido -->
                  <div class="form-group col-md-6">
                    <label for="producto_liquido">PRODUCTO LIQUIDO</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="form.producto_liquido"
                      placeholder="Producto Líquido"
                      :class="
                        $v.form.producto_liquido.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Remisión -->
                  <div class="form-group col-md-6">
                    <label for="remision">N° REMISION</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="form.remision"
                      placeholder="Orden de Cargue"
                      :class="
                        $v.form.remision.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <!-- Despachado -->
                  <div class="form-group col-md-8">
                    <label for="despachado">DESPACHADO A:</label>
                    <input
                      type="text"
                      style="font-size: 13px"
                      v-model="form.despachado_a"
                      placeholder="Despachado"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.despachado_a.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Código -->
                  <div class="form-group col-md-4">
                    <label for="codigo">CÓDIGO</label>
                    <input
                      type="text"
                      style="font-size: 13px"
                      v-model="form.codigo_destino"
                      placeholder="Codigo Destino"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.codigo_destino.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Dirección -->
                  <div class="form-group col-md-8">
                    <label for="direccion_destino">DIRECCIÓN:</label>
                    <input
                      type="text"
                      style="font-size: 13px"
                      v-model="form.direccion_destino"
                      placeholder="Dirección"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.direccion_destino.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!--Ciudad Destino -->
                  <div class="form-group col-md-4">
                    <label for="ciudad_destino">CIUDAD DESTINO</label>
                    <v-select
                      v-model="ciudad_destino"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.ciudades"
                      @input="selectCiudadDestino()"
                      :class="[
                        $v.form.ciudad_destino_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <!-- Cédula Conductor -->
                  <div class="form-group col-md-4">
                    <label for="campo_productor">CÉDULA</label>
                    <input
                      type="number"
                      style="font-size: 13px"
                      v-model="form.documento_conductor"
                      placeholder="Cédula Conductor"
                      label="cedula"
                      class="form-control form-control-sm"
                      @change="buscarConductores()"
                      :class="
                        $v.form.documento_conductor.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Nombre Conductor -->
                  <div class="form-group col-md-8">
                    <label for="conductor">NOMBRE DEL CONDUCTOR</label>
                    <input
                      type="text"
                      v-model="form.nombre_conductor"
                      style="font-size: 13px"
                      placeholder="Nombre Conductor"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.nombre_conductor.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="!form.documento_conductor"
                    />
                    <div class="error" v-if="!form.documento_conductor">
                      <span class="badge bg-danger" style="font-size: 10px"
                        >Digite Cédula</span
                      >
                    </div>
                  </div>
                  <!-- Transportadora -->
                  <div class="form-group col-md-5">
                    <label for="empresa_transportadora"
                      >EMPRESA TRANSPORTADORA</label
                    >
                    <input
                      type="text"
                      v-model="form.nombre_transportadora"
                      style="font-size: 13px"
                      placeholder="Nombre Conductor"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.nombre_transportadora.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Vehículo -->
                  <div class="form-group col-md-2">
                    <label for="cabezote">PLACA DEL VEHÍCULO</label>
                    <input
                      type="text"
                      style="font-size: 13px"
                      v-model="form.vehiculo"
                      placeholder="Placa Vehículo"
                      class="form-control form-control-sm"
                      @change="buscarVehiculos()"
                      :class="
                        $v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Remolque -->
                  <div class="form-group col-md-2">
                    <label for="remolque">PLACA DEL REMOLQUE</label>
                    <input
                      type="text"
                      style="font-size: 13px"
                      v-model="form.remolque"
                      placeholder="Placa Remolque"
                      class="form-control form-control-sm"
                      @change="buscarRemolques()"
                      :class="
                        $v.form.remolque.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Tipo Vehículo -->
                  <div class="form-group col-md-3">
                    <label for="remolque">TIPO VEHÍCULO</label>
                    <v-select
                      v-model="tipo_vehiculo"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.tipos_vehiculos"
                      @input="selectTiposVehiculos()"
                      :class="[
                        $v.form.tipo_vehiculo_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <!-- Sitio Origen -->
                  <div class="form-group col-md-6">
                    <label for="lugar_origen">LUGAR DE ORIGEN</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="form.sitio_origen"
                      :class="
                        $v.form.sitio_origen.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Fecha Cargue -->
                  <div class="form-group col-md-3">
                    <label for="fecha_cargue">FECHA</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="form.fecha_cargue"
                      @input="validaFechas()"
                      :class="
                        $v.form.fecha_cargue.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Hora Cargue -->
                  <div class="form-group col-md-3">
                    <label for="hora_cargue">HORA DE SALIDA</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="form.hora_cargue"
                      @input="validaFechas()"
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                      :class="
                        $v.form.hora_cargue.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Sitio Destino -->
                  <div class="form-group col-md-6">
                    <label for="lugar_destino">LUGAR DE DESTINO</label>
                    <v-select
                      v-model="sitio_destino"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="selectSitioDestino()"
                      :class="[
                        $v.form.sitio_destino_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    ></v-select>
                  </div>
                  <!-- Horas Vigencia -->
                  <div class="form-group col-md-3">
                    <label for="hora_vigencia">HORAS DE VIGENCIA</label>
                    <input
                      type="number"
                      style="font-size: 13px"
                      v-model="form.horas_vigencia"
                      placeholder="Horas Vigencia"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.horas_vigencia.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Horas Adicional -->
                  <div class="form-group col-md-3">
                    <label for="hora_adicional">HORA ADICIONAL</label>
                    <input
                      type="number"
                      style="font-size: 13px"
                      v-model="form.hora_adicional"
                      placeholder="Horas Adicional"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.hora_adicional.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <!-- Sellos -->
                  <div class="form-group col-md-12">
                    <label for="sellos">SELLOS</label>
                    <textarea
                      cols="20"
                      rows="2"
                      class="form-control form-control-sm"
                      v-model="form.sellos"
                      :class="
                        $v.form.sellos.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <!-- Alerta Sellos -->
                  <div
                    class="form-group col-md-12 btn alert-default-warning image-preview-input col-md-12"
                  >
                    <h5>
                      <i class="icon fas fa-exclamation-triangle"></i>
                      Atención!
                    </h5>
                    Por favor ingresa los sellos separados por
                    <strong>comas</strong>, por ejemplo:
                    <strong>12364,11122</strong>
                  </div>
                  <!-- Gov -->
                  <div class="form-group col-md-4">
                    <label for="gov">GOV</label>
                    <div class="input-group">
                      <input
                        type="number"
                        style="font-size: 13px"
                        v-model="form.gov"
                        class="form-control form-control-sm"
                        step="any"
                        :class="
                          $v.form.gov.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <!-- Gsv -->
                  <div class="form-group col-md-4">
                    <label for="gsv">GSV</label>
                    <div class="input">
                      <input
                        type="number"
                        style="font-size: 13px"
                        v-model="form.gsv"
                        class="form-control form-control-sm"
                        step="any"
                        :class="
                          $v.form.gsv.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <!-- Nsv -->
                  <div class="form-group col-md-4">
                    <label for="nsv">NSV</label>
                    <input
                      type="number"
                      style="font-size: 13px"
                      v-model="form.nsv"
                      class="form-control form-control-sm"
                      step="any"
                      :class="$v.form.nsv.$invalid ? 'is-invalid' : 'is-valid'"
                    />
                  </div>
                  <!-- Observaciones -->
                  <div class="form-group col-md-12">
                    <label for="observaciones">OBSERVACIONES</label>
                    <textarea
                      cols="30"
                      rows="4"
                      class="form-control form-control-sm"
                      v-model="form.observaciones"
                      :class="
                        $v.form.observaciones.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="descripcion_producto"
                      >DESCRIPCIÓN PRODUCTO</label
                    >
                    <textarea
                      cols="30"
                      rows="4"
                      class="form-control form-control-sm"
                      v-model="form.descripcion_producto"
                      :class="
                        $v.form.descripcion_producto.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row justify-content-center" style="font-size: 12px">
                  <!-- Analisis Laboratorio -->
                  <div class="form-group col-md-3">
                    <label for="temp">ANALISIS DE LAB (TEMP°)</label>
                    <input
                      type="number"
                      style="font-size: 13px"
                      v-model="form.analisis_laboratorio"
                      min="50"
                      max="250"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.analisis_laboratorio.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Factor Temperatura -->
                  <div class="form-group col-md-3">
                    <label for="factor">FACTOR TEMPERATURA</label>
                    <input
                      type="number"
                      style="font-size: 13px"
                      v-model="form.factor_temperatura"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.factor_temperatura.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Api -->
                  <div class="form-group col-md-2">
                    <label>A.P.I</label>
                    <input
                      type="number"
                      style="font-size: 13px"
                      v-model="form.api"
                      class="form-control form-control-sm"
                      :class="$v.form.api.$invalid ? 'is-invalid' : 'is-valid'"
                    />
                  </div>
                  <!-- Bsw -->
                  <div class="form-group col-md-2">
                    <label for="bsw">B.S.W</label>
                    <input
                      type="number"
                      style="font-size: 13px"
                      v-model="form.bsw"
                      class="form-control form-control-sm"
                      :class="$v.form.bsw.$invalid ? 'is-invalid' : 'is-valid'"
                    />
                  </div>
                  <!-- Azufre -->
                  <div class="form-group col-md-2">
                    <label for="azufre">S%</label>
                    <input
                      type="number"
                      style="font-size: 13px"
                      v-model="form.azufre"
                      class="form-control form-control-sm"
                      :class="
                        $v.form.azufre.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <!-- Ventana Enturne Descargue -->
                  <div class="form-group col-md-6">
                    <label for="ventana_enturne_descargue"
                      >Ventana Enturne Descargue</label
                    >
                    <v-select
                      v-model="ventana_descargue"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.ventanas_descargue"
                      @input="selectVentanaDescargue()"
                      @search="getVentanaEnturnamiento"
                      :class="[
                        $v.form.ventana_enturne_descargue_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      :disabled="!form.sitio_destino_id"
                    ></v-select>
                    <div class="error" v-if="!form.sitio_destino_id">
                      <span class="badge bg-danger"
                        >Digite lugar de destino</span
                      >
                    </div>
                  </div>
                  <!-- Tipo Sitio -->
                  <div class="form-group col-md-3">
                    <label for="tipo_sitio">Tipo Sitio</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.sitio_type"
                      :class="
                        $v.form.sitio_type.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="list in listasForms.tipos_sitio"
                        :key="list.numeracion"
                        :value="list.numeracion"
                      >
                        {{ list.descripcion }}
                      </option>
                    </select>
                  </div>
                  <!-- Sitio -->
                  <div class="form-group col-md-3" v-if="form.sitio_type == 1">
                    <label for="sitio">Sitio</label>
                    <v-select
                      v-model="sitio"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.sitios"
                      @input="selectSitio()"
                      :class="[
                        $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    ></v-select>
                  </div>
                  <!--  Punto Gestión-->
                  <div class="form-group col-md-3" v-if="form.sitio_type == 2">
                    <label for="punto">Punto Gestión</label>
                    <v-select
                      v-model="punto_gestion"
                      class="form-control form-control-sm p-0"
                      placeholder="Nombre"
                      label="nombre"
                      :options="listasForms.puntos_gestion"
                      @input="selectPuntoGestion()"
                      :class="[
                        $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    ></v-select>
                  </div>
                </div>
                <div class="row" style="font-size: 12px">
                  <!-- Fecha Enturne Descargue -->
                  <div class="form-group col-md-3">
                    <label for="fecha_enturne_descargue"
                      >FECHA ENTURNE DESCARGUE</label
                    >
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="form.fecha_enturne_descargue"
                      @input="validaFechas()"
                      :class="
                        $v.form.fecha_enturne_descargue.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Hora Enturne Descargue -->
                  <div class="form-group col-md-3">
                    <label for="hora_cargue">HORA ENTURNE DESCARGUE</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="form.hora_enturne_descargue"
                      @input="validaFechas()"
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                      :class="
                        $v.form.hora_enturne_descargue.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Fecha Descargue -->
                  <div class="form-group col-md-3">
                    <label for="fecha_cargue">FECHA DESCARGUE</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="form.fecha_descargue"
                      @input="validaFechas()"
                      :class="
                        $v.form.fecha_descargue.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <!-- Hora Cargue -->
                  <div class="form-group col-md-3">
                    <label for="hora_cargue">HORA DESCARGUE</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="form.hora_descargue"
                      @input="validaFechas()"
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                      :class="
                        $v.form.hora_descargue.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer alert-default-success">
              <div class="row">
                <div class="col-md-2">
                  <button class="btn bg-gray-dark col-md-11" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    class="btn bg-navy col-md-11"
                    v-show="!$v.form.$invalid"
                    v-if="
                      $store.getters.can('hidrocarburos.guiaCompraSTrans.save')
                    "
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i>
                    <br />Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import moment from "moment";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "GuiasCompraSinTransporteForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      form: {
        numero_guia: null,
        digito_verificacion: null,
        tipo_producto_id: null,
        ciudad_origen_id: null,
        fecha_expedicion: null,
        hora_expedicion: null,
        producto_liquido: null,
        remision: null,
        despachado_a: null,
        codigo_destino: null,
        direccion_destino: null,
        ciudad_destino_id: null,
        documento_conductor: null,
        nombre_conductor: null,
        transportadora_id: null,
        nombre_transportadora: null,
        vehiculo: null,
        remolque: null,
        tipo_vehiculo_id: null,
        sitio_origen: null,
        fecha_cargue: null,
        hora_cargue: null,
        sitio_destino: null,
        sitio_destino_id: null,
        sitio_type: null,
        sitio_id: null,
        horas_vigencia: null,
        hora_adicional: null,
        gov: null,
        gsv: null,
        nsv: null,
        analisis_laboratorio: null,
        factor_temperatura: null,
        observaciones: null,
        descripcion_producto: null,
        api: null,
        bsw: null,
        azufre: null,
        tipo_operacion: 1,
        ventana_enturne_descargue_id: null,
        fecha_enturne_descargue: null,
        hora_enturne_descargue: null,
        fecha_descargue: null,
        hora_descargue: null,
        sellos: null,
      },
      resultado: null,
      ciudad_origen: [],
      tipo_producto: [],
      ciudad_destino: [],
      transportadora: [],
      sitio_destino: [],
      tipo_vehiculo: [],
      ventana_descargue: [],
      sitio_type: [],
      sitio: [],
      punto_gestion: [],
      id: null,
      accion: "",
      metodo: "",
      listasForms: {
        tipo_productos: [],
        tipos_vehiculos: [],
        transportadoras: [],
        sitios: [],
        tipos_sitio: [],
        ventanas_descargue: [],
        puntos_gestion: [],
      },
    };
  },
  validations: {
    form: {
      numero_guia: {
        required,
      },
      digito_verificacion: {
        required,
      },
      tipo_producto_id: {
        required,
      },
      ciudad_origen_id: {
        required,
      },
      fecha_expedicion: {
        required,
      },
      hora_expedicion: {
        required,
        timeFormat,
      },
      producto_liquido: {
        required,
      },
      remision: {
        required,
      },
      despachado_a: {
        required,
      },
      codigo_destino: {
        required,
      },
      direccion_destino: {
        required,
      },
      ciudad_destino_id: {
        required,
      },
      documento_conductor: {
        required,
      },
      nombre_conductor: {
        required,
      },
      transportadora_id: {
        required,
      },
      nombre_transportadora: {
        required,
      },
      vehiculo: {
        required,
      },
      remolque: {
        required,
      },
      tipo_vehiculo_id: {
        required,
      },
      sitio_origen: {
        required,
      },
      fecha_cargue: {
        required,
      },
      hora_cargue: {
        required,
        timeFormat,
      },
      sitio_destino_id: {
        required,
      },
      sitio_type: {
        required,
      },
      sitio_id: {
        required,
      },
      horas_vigencia: {
        required,
      },
      hora_adicional: {
        required,
      },
      gov: {
        required,
      },
      gsv: {
        required,
      },
      nsv: {
        required,
      },
      analisis_laboratorio: {
        required,
      },
      factor_temperatura: {
        required,
      },
      observaciones: {
        required,
      },
      descripcion_producto: {
        required,
      },
      api: {
        required,
      },
      bsw: {
        required,
      },
      azufre: {
        required,
      },
      ventana_enturne_descargue_id: {
        required,
      },
      fecha_descargue: {
        required,
      },
      hora_descargue: {
        required,
      },
      fecha_enturne_descargue: {
        required,
      },
      hora_enturne_descargue: {
        required,
      },
      sellos: {
        required,
      },
    },
  },
  methods: {
    async init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        let data = this.$route.params.data_edit;
        this.form = {
          numero_guia: data.numero_guia,
          digito_verificacion: null,
          tipo_producto_id: data.tipo_producto_id,
          ciudad_origen_id: data.ciudad_origen_id,
          producto_liquido: data.producto_liquido,
          remision: data.remision,
          despachado_a: data.despachado_a,
          codigo_destino: data.codigo_destino,
          direccion_destino: data.direccion_destino,
          ciudad_destino_id: data.ciudad_destino_id,
          documento_conductor: data.documento_conductor,
          nombre_conductor: data.nombre_conductor,
          transportadora_id: data.transportadora_id,
          nombre_transportadora: data.nombre_transportadora,
          vehiculo: data.vehiculo,
          remolque: data.remolque,
          tipo_vehiculo_id: data.tipo_producto_id,
          sitio_origen: data.sitio_origen,
          sitio_destino: data.sitio_destino,
          sitio_destino_id: data.sitio_destino_id,
          sitio_type: data.sitio_type == "App\\Sitio" ? 1 : 2,
          sitio_id: data.sitio_id,
          horas_vigencia: data.horas_vigencia,
          hora_adicional: data.hora_adicional,
          gov: data.gov,
          gsv: data.gsv,
          nsv: data.nsv,
          analisis_laboratorio: data.analisis_laboratorio,
          factor_temperatura: data.factor_temperatura,
          observaciones: data.observaciones,
          descripcion_producto: data.descripcion,
          api: data.api,
          bsw: data.bsw,
          azufre: data.azufre,
          tipo_operacion: data.tipo_operacion,
          ventana_enturne_descargue_id: data.ventana_enturne_descargue_id,
          sellos: data.sellos,
        };
        this.form.fecha_expedicion = data.fecha_expedicion.slice(0, 10);
        this.form.hora_expedicion = data.fecha_expedicion.slice(11, 19);
        this.form.fecha_enturne_descargue = data.fecha_enturne_descargue
          ? data.fecha_enturne_descargue.slice(0, 10)
          : null;
        this.form.fecha_cargue = data.fecha_hora_cargue.slice(0, 10);
        this.form.hora_cargue = data.fecha_hora_cargue.slice(11, 19);
        this.form.fecha_enturne_descargue = data.fecha_enturne_descargue
          ? data.fecha_enturne_descargue.slice(0, 10)
          : null;
        this.form.hora_enturne_descargue = data.fecha_enturne_descargue
          ? data.fecha_enturne_descargue.slice(11, 19)
          : null;
        this.form.fecha_descargue = data.fecha_descargue
          ? data.fecha_descargue.slice(0, 10)
          : null;
        this.form.hora_descargue = data.fecha_descargue
          ? data.fecha_descargue.slice(11, 19)
          : null;
        this.tipo_producto = data.tipo_producto;
        this.ciudad_origen = data.ciudad_origen;
        this.ciudad_destino = data.ciudad_destino;
        if (data.sitio_type == "App\\Sitio") {
          this.sitio = data.sitio;
        } else {
          this.punto_gestion = data.punto_gestion;
        }
        this.sitio_destino = data.sitio_destino;
        this.ventana_descargue = data.ventana_enturne_descargue_id
          ? data.ventana_descargue
          : null;
        this.metodo = "PUT";
        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.cargando = false;
      }
      this.cargando = false;
    },

    /* Buscar Listas */
    async buscarGuia() {
      if (this.form.numero_guia) {
        this.cargando = true;
        let dato = this.form.numero_guia;
        let res = await this.getGuia(dato);

        if (dato.length !== 11 && dato.length !== 12) {
          this.cargando = false;
          this.form.numero_guia = null;
          this.form.digito_verificacion = null;
          await this.$swal({
            icon: "error",
            title: "La guía debe tener 11 o 12 digitos.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          return false;
        }

        if (res && res.length != 0) {
          this.cargando = false;
          await this.$swal({
            icon: "success",
            title: "La guía se encuentra creada en el sistema.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          await this.selectGuia(res[0]);
        } else {
          this.form.digito_verificacion = null;
          this.form.guia_id = null;
          this.$swal({
            icon: "warning",
            title: "La guía digitado NO se encuentra registrada.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
        this.cargando = false;
      }
    },

    async getGuia(guia) {
      var res = null;
      let params = {
        numero_guia_compras: guia,
      };
      await axios
        .get("/api/hidrocarburos/guias/listaSelect", {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async getTiposProducto() {
      await axios
        .get("/api/admin/productos/lista", {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then((response) => {
          this.listasForms.tipo_productos = response.data;
        });
    },

    async getCiudad() {
      await axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },

    async getTiposVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipoVehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarConductores() {
      this.cargando = true;
      let dato = this.form.documento_conductor;
      let res = await this.validarStatus(dato, "C");
      /* Existe Conductor */
      if (res && res.length != 0) {
        /* Documentación */
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.cargando = false;
          if (res.validacion.error.documentos !== undefined) {
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined ||
              res.validacion.error.inactivo == false
            ) {
              this.form.documento_conductor = null;
              this.form.nombre_conductor = null;
              return false;
            }
          }
          if (res.validacion.error.inactivo == false) {
            this.form.documento_conductor = null;
            this.form.nombre_conductor = null;
            this.cargando = false;
            return false;
          }
        }
        /* Falta Campo Formulario Conductor */
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          this.cargando = false;
          return false;
        }
        /* Linea Negocio Conductor */
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El Conductor no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          this.cargando = false;
          return false;
        }
        /* Viaje Activo */
        /* if (res.viaje.length > 0) {
        this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          await this.$swal({
            icon: "error",
            title: `El conductor se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } */
        /* Postulación Activa */
        /* if (res.postulacion.length > 0) {
          this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          await this.$swal({
            icon: "error",
            title: `El conductor cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } */
        /* Postulación Adicional Activa */
        /* if (res.postulacion_adicionales.length > 0) {
          this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          await this.$swal({
            icon: "error",
            title: `El conductor ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */
        await this.$swal({
          icon: "success",
          title: "El conductor es apto..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await this.selectConductor(res[0]);
      } else {
        this.form.conductor_id = null;
        await this.$swal({
          icon: "warning",
          title: "El conductor digitado NO se encuentra registrado.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      this.cargando = false;
    },

    async buscarVehiculos() {
      this.cargando = true;
      let dato = this.form.vehiculo;
      let res = await this.validarStatus(dato, "V"); //SVC962, THX689
      /* Existe Vehículo */
      if (res && res.length != 0) {
        /* Dcoumentación */
        if (res.validacion.error) {
          this.cargando = false;
          await this.msgDocumentacion(res);
          if (res.validacion.error.documentos != undefined) {
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              this.form.vehiculo = null;
              this.cargando = false;
              return false;
            }
          }
          if (res.validacion.error.inactivo == false) {
            this.form.vehiculo = null;
            this.cargando = false;
            return false;
          }
        }

        /* Viajes Activos */
        /* if (res.viaje.length > 0) {
          this.form.vehiculo = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        //Validar si ya se encuentra con una postulacion
        /* if (res.postulacion.length > 0) {
          this.form.vehiculo = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo cuenta con una postulación activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        //Validar si ya se encuentra con una postulacion adicionales
        /* if (res.postulacion_adicionales.length > 0) {
          this.form.vehiculo = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo ya está en un proceso de postulación de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        //Validar si el vehículo tiene peso asignado.
        if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.fomr.vehiculo = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        /* Campos Vacios */
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Vehículos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.vehiculo = null;
          this.cargando = false;
          return false;
        }

        /* Línea Negocio */
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El vehículo no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.vehiculo = null;
          this.cargando = false;
          return false;
        }

        await this.$swal({
          icon: "success",
          title: "El vehículo es apto..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await this.selectVehiculo(res[0]);
      } else {
        this.form.vehiculo_id = null;
        this.$swal({
          icon: "warning",
          title: "El vehículo digitado NO se encuentra registrado.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.cargando = false;
        return false;
      }
      this.cargando = false;
    },

    async buscarRemolques() {
      this.cargando = true;
      let dato = this.form.remolque;
      let res = await this.validarStatus(dato, "R");
      /* Existe Remolque */
      if (res && res.length != 0) {
        /* Documentación */
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.cargando = false;
          if (res.validacion.error.documentos !== undefined) {
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined ||
              res.validacion.error.inactivo == false
            ) {
              this.form.remolque = null;
              this.cargando = false;
              return false;
            }
          }

          if (res.validacion.error.inactivo == false) {
            this.remolque.placa = null;
            this.formEnvio.remolque_id = null;
            this.formEnvio.remolque = {};
            this.cargando = false;
            return false;
          }
        }

        /* Campos Faltantes */
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Relmoques`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.remolque = null;
          this.cargando = false;
          return false;
        }

        /* Línea Negocio */
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El Remolque no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.remolque = null;
          this.cargando = false;
          return false;
        }

        /* Viaje Activo */
        /* if (res.viaje.length > 0) {
          this.form.remolque = null;
          await this.$swal({
            icon: "error",
            title: `El remolque se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        /* Postulación Activa */
        /* if (res.postulacion.length > 0) {
          this.form.remolque = null;
          await this.$swal({
            icon: "error",
            title: `El remolque cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        /* Validar si el rermolque esta en una postulacion de adicionales. */
        /* if (res.postulacion_adicionales.length > 0) {
          this.form.remolque = null;
          await this.$swal({
            icon: "error",
            title: `El remolque ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        //Validar si el rermolque tiene peso asignado.
        /*  if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.form.remolque = null;
          await this.$swal({
            icon: "error",
            title: `El remolque no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        await this.$swal({
          icon: "success",
          title: "El remolque es apto..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await this.selectRemolque(res[0]);
      } else {
        this.form.remolque_id = null;
        this.$swal({
          icon: "warning",
          title: "El remolque digitado NO se encuentra registrado.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }

      this.cargando = false;
    },

    async getTipoVh() {
      await axios
        .get("api/admin/tiposVehiculos/lista", {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then((response) => {
          this.listasForms.tipos_vehiculos = response.data;
        });
    },

    async getSitio() {
      let me = this;
      this.listasForms.sitios = [];
      var url = "api/admin/sitios/lista";
      await axios
        .get(url)
        .then(function(response) {
          me.listasForms.sitios = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getVentanaEnturnamiento() {
      axios
        .get("api/hidrocarburos/ventanasEnturnamiento/lista", {
          params: {
            tipo_ventana: 2,
            sitio: this.form.sitio_destino_id,
          },
        })
        .then((response) => {
          this.listasForms.ventanas_descargue = response.data;
        });
    },

    async getTipoSitio() {
      await axios.get("/api/lista/25").then((response) => {
        this.listasForms.tipos_sitio = response.data;
      });
    },

    async getPuntoGestion() {
      await axios
        .get("/api/admin/puntos_gestion/lista", {
          params: {
            estado: 1,
          },
        })
        .then((response) => {
          this.listasForms.puntos_gestion = response.data;
        });
    },

    /* Seleccionar listas */
    selectGuia(guia) {
      if (this.form.numero_guia) {
        this.form.guia_id = guia.id;
        this.form.digito_verificacion = guia.digito_verificacion;
      }
    },

    selectTipoProducto() {
      this.form.tipo_producto_id = null;
      if (this.tipo_producto) {
        this.form.tipo_producto_id = this.tipo_producto.id;
      }
    },

    selectempresa() {
      this.form.transportadora_id = null;
      if (this.transportadora) {
        this.form.transportadora_id = this.transportadora.id;
      }
    },

    selectCiudadOrigen() {
      this.form.ciudad_origen_id = null;
      if (this.ciudad_origen) {
        this.form.ciudad_origen_id = this.ciudad_origen.id;
      }
    },

    selectCiudadDestino() {
      this.form.ciudad_destino_id = null;
      if (this.ciudad_destino) {
        this.form.ciudad_destino_id = this.ciudad_destino.id;
      }
    },

    selectConductor(conductor) {
      if (this.form.documento_conductor) {
        this.form.conductor_id = conductor.id;
        this.form.documento_conductor = conductor.numero_documento;
        this.form.nombre_conductor =
          conductor.nombres + " " + conductor.apellidos;
      }
    },

    selectVehiculo(vehiculo) {
      if (this.form.vehiculo) {
        this.form.vehiculo_id = vehiculo.id;
        this.form.vehiculo = vehiculo.placa;
      }
    },

    selectTiposVehiculos() {
      this.form.tipo_vehiculo_id = null;
      if (this.tipo_vehiculo) {
        this.form.tipo_vehiculo_id = this.tipo_vehiculo.id;
      }
    },

    selectRemolque(remolque) {
      if (this.form.remolque) {
        this.form.remolque_id = remolque.id;
        this.form.remolque = remolque.placa;
      }
    },

    selectSitioDestino() {
      this.form.sitio_destino_id = null;
      if (this.sitio_destino) {
        this.form.sitio_destino_id = this.sitio_destino.id;
        this.form.sitio_destino = this.sitio_destino.nombre;
      }
    },

    selectVentanaDescargue() {
      this.form.ventana_enturne_descargue_id = null;
      if (this.ventana_descargue) {
        this.form.ventana_enturne_descargue_id = this.ventana_descargue.id;
      }
    },

    selectSitio() {
      this.form.sitio_id = null;
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
      }
    },

    selectPuntoGestion() {
      this.form.sitio_id = null;
      if (this.punto_gestion) {
        this.form.sitio_id = this.punto_gestion.id;
      }
    },

    async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/hidrocarburos/postulaciones/listaConductores";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/hidrocarburos/postulaciones/listaRemolques";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/hidrocarburos/postulaciones/listaVehiculos";
      }

      let params = {
        entidad,
        dato,
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos !== undefined) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
          erroHtml += "</div>";
          await this.$swal({
            html: erroHtml,
            showCancelButton: false,
            focusConfirm: true,
            icon: "error",
            title: "Aviso en documentacion",
            showConfirmButton: false,
            showCloseButton: true,
            footer:
              "<small style='text-align:center'><b>Desde el 1 de abril se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
          });
        } else {
          this.$swal({
            icon: "error",
            title: res.validacion.error.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    /* Validaciones */
    validaFechas() {
      if (
        this.form.fecha_expedicion &&
        this.form.hora_expedicion &&
        this.form.fecha_cargue &&
        this.form.hora_cargue
      ) {
        var fecha_menor = new Date(
          this.form.fecha_expedicion + " " + this.form.hora_expedicion
        );

        var fecha_mayor = new Date(
          this.form.fecha_cargue + " " + this.form.hora_cargue
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.$swal({
            icon: "error",
            title: `La fecha y hora de expedición no puede ser mayor a la fecha y hora de salida...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.fecha_expedicion = null;
          this.form.hora_expedicion = null;
        }
      }

      //Validacion fechas descargue
      if (
        this.form.fecha_descargue &&
        this.form.hora_descargue &&
        this.form.fecha_enturne_descargue &&
        this.form.hora_enturne_descargue
      ) {
        var fecha_enturne = new Date(
          this.form.fecha_enturne_descargue +
            " " +
            this.form.hora_enturne_descargue
        );

        var fecha_descargue = new Date(
          this.form.fecha_descargue + " " + this.form.hora_descargue
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_enturne > fecha_descargue) {
          this.$swal({
            icon: "error",
            title: `La fecha y hora de enturne descargue no puede ser mayor a la fecha y hora de descargue...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.fecha_expedicion = null;
          this.form.hora_expedicion = null;
        }
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.form.numero_guia =
          this.form.numero_guia + this.form.digito_verificacion;

        this.form.user_id = this.$store.state.user.id;
        this.form.sitio_type =
          this.form.sitio_type == 1 ? "App\\Sitio" : "App\\PuntoGestion";

        this.form.fecha_expedicion =
          this.form.fecha_expedicion + " " + this.form.hora_expedicion;

        this.form.fecha_hora_cargue =
          this.form.fecha_cargue + " " + this.form.hora_cargue;

        this.form.fecha_enturne_descargue =
          this.form.fecha_enturne_descargue +
          " " +
          this.form.hora_enturne_descargue;

        this.form.fecha_descargue =
          this.form.fecha_descargue + " " + this.form.hora_descargue;

        this.form.estado = this.form.sitio_type == 1 ? 7 : 1;

        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/detGuiaCompraSTrans",
          data: this.form,
        })
          .then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "La guía se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace({
        path: `/Hidrocarburos/GuiasCompraSinTransporte`,
      });
    },
  },

  async mounted() {
    await this.init();
    await this.getTiposVehiculos();
    await this.getTiposProducto();
    await this.getCiudad();
    await this.getTipoVh();
    await this.getSitio();
    await this.getTipoSitio();
    await this.getPuntoGestion();
  },
};
</script>
